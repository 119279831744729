import {
  Administrador,
  SuperAdmin,
  Ventas,
  Socios,
  Marketing,
} from "../../utils/rolConstants";

export const menuOpciones1 = [
  {
    icon: "calendar",
    label: "Calendario",
    roles: [Administrador],
    subMenus: [
      {
        route: "/inicio",
        label: "Ver",
        roles: [Administrador],
      },
    ],
  },
  {
    icon: "users",
    label: "Empleados",
    roles: [Administrador],
    subMenus: [
      {
        route: "/catalogos/empleados",
        label: "Listar",
        roles: [Administrador],
      },
      {
        route: "/catalogos/empleados/bitacoras",
        label: "Bitácoras",
        roles: [Administrador],
      },
      {
        route: "/catalogos/empleados/evaluacion",
        label: "Evaluación de Empleado",
        roles: [Administrador],
      },
      {
        route: "/catalogos/empleados/swipe",
        label: "Swipe",
        roles: [Administrador],
      },
    ],
  },
];

export const menuOpcionesAdministradorGeocerca = [
  {
    icon: "map",
    label: "Zonas",
    roles: [Administrador],
    subMenus: [
      {
        route: "/catalogos/zonas",
        label: "Listar",
        roles: [Administrador],
      },
    ],
  },
  {
    icon: "address card outline",
    label: "Recursos Humanos",
    roles: [Administrador],
    subMenus: [
      {
        route: "/catalogos/recursosHumanos/horasExtra",
        label: "Horas Extras",
        roles: [Administrador],
      },
      {
        route: "/catalogos/recursosHumanos/Permisos",
        label: "Permisos",
        roles: [Administrador],
      },
      {
        route: "/catalogos/recursosHumanos/Faltas",
        label: "Justificar Faltas",
        roles: [Administrador],
      },
    ],
  },
]
export const menuOpciones2 = [
  {
    icon: "map",
    label: "Zonas",
    roles: [Administrador],
    subMenus: [
      {
        route: "/catalogos/zonas",
        label: "Listar",
        roles: [Administrador],
      },
    ],
  },
  {
    icon: "calendar alternate outline",
    label: "Prenómina",
    roles: [Administrador],
    subMenus: [
      {
        route: "/nomina/prenomina",
        label: "Cálculo Prenómina",
        roles: [Administrador],
      },
      {
        route: "/nomina/periodos",
        label: "Período",
        roles: [Administrador],
      },
    ],
  },
  {
    icon: "address card outline",
    label: "Recursos Humanos",
    roles: [Administrador],
    subMenus: [
      {
        route: "/catalogos/recursosHumanos/horasExtra",
        label: "Horas Extras",
        roles: [Administrador],
      },
      {
        route: "/catalogos/recursosHumanos/Permisos",
        label: "Permisos",
        roles: [Administrador],
      },
      {
        route: "/catalogos/recursosHumanos/Faltas",
        label: "Justificar Faltas",
        roles: [Administrador],
      },
      {
        route: "/catalogos/recursosHumanos/prestamos",
        label: "Préstamos",
        roles: [Administrador],
      },
    ],
  },
  {
    icon: "file",
    label: "Subcontratos",
    roles: [Administrador],
    subMenus: [
      {
        route: "/catalogos/subContratos",
        label: "Subcontrato Individual",
        roles: [Administrador],
      },
      {
        route: "/catalogos/empresas",
        label: "Subcontrato Empresas",
        roles: [Administrador],
      },
      // {
      //   route: "/catalogos/subContratos/SeguimientoSubContratos",
      //   label: "Bitácora Fotográfica",
      //   roles: [Administrador],
      // },
    ],
  },
  {
    icon: "area graph",
    label: "Estadísticas",
    roles: [Administrador],
    subMenus: [
      {
        route: "/catalogos/estadisticas/presupuesto",
        label: "Presupuesto",
        roles: [Administrador],
      },
      {
        route: "/catalogos/estadisticas/faltasRetardos",
        label: "Faltas y Retardos",
        roles: [Administrador],
      },
      {
        route: "/catalogos/estadisticas/recursosHumanos",
        label: "Recursos Humanos",
        roles: [Administrador],
      },
    ],
  },
  // {
  //   icon: "file alternate outline",
  //   label: "Subcontrato Individual",
  //   roles: [Administrador],
  //   subMenus: [
  //     {
  //       route: "/catalogos/subContratos",
  //       label: "Listar",
  //       roles: [Administrador],
  //     },
  //   ],
  // },
  // {
  //   icon: "building",
  //   label: "Subcontrato Empresas",
  //   roles: [Administrador],
  //   subMenus: [
  //     {
  //       route: "/catalogos/empresas",
  //       label: "Listar",
  //       roles: [Administrador],
  //     },
  //   ],
  // },
  // {
  //   icon: 'history',
  //   label: 'Seguimiento de Subcontratos',
  //   roles: [Administrador],
  //   subMenus: [
  //     {
  //       route: '/catalogos/subContratos/SeguimientoSubContratos',
  //       label: 'Seguimiento',
  //       roles: [Administrador],
  //     },
  //   ],
  // },
];
export const menuOpcionesAdmin = [
  {
    icon: "tasks",
    label: "Planes",
    roles: [SuperAdmin, Socios],
    subMenus: [
      {
        route: "/catalogos/administracion/planes",
        label: "Ver Planes",
        roles: [SuperAdmin, Socios],
      },
      {
        route: "/catalogos/administracion/solicitudCancelacion",
        label: "Solicitudes de Cancelación",
        roles: [SuperAdmin],
      },
      {
        route: "/catalogos/administracion/planesCancelados",
        label: "Planes Cancelados",
        roles: [SuperAdmin],
      },
    ],
  },
  {
    label: "Tablero General",
    roles: [SuperAdmin, Socios, Marketing],
    icon: "file alternate outline",
    subMenus: [
      {
        route: "/catalogos/administracion/tableros/membresias",
        label: "Membresías",
        roles: [SuperAdmin, Socios, Marketing],
      },
      {
        route: "/catalogos/administracion/tableros/general",
        label: "Clientes y Empleados",
        roles: [SuperAdmin, Socios, Marketing],
      },
    ],
  },
  {
    route: "/catalogos/administracion/tableros/finanzas",
    label: "Ingresos",
    roles: [SuperAdmin, Ventas, Socios],
    icon: "dollar sign",
  },
  {
    route: "/catalogos/administracion/ventas",
    label: "Vendedores",
    roles: [SuperAdmin],
    icon: "clipboard list",
  },
  {
    route: "/catalogos/administracion/usuarios",
    label: "Usuarios",
    roles: [SuperAdmin],
    icon: "user",
  },
  {
    route: "/catalogos/empleados/evaluacion",
    label: "Evaluación de empleados",
    roles: [SuperAdmin],
    icon: "clipboard outline",
  },
  // {
  //   route: "/catalogos/administracion/transferencias",
  //   label: "Configuración de transferencia",
  //   roles: [SuperAdmin],
  //   icon: "cog",
  // },
  {
    route: "/catalogos/administracion/serviciosExtras",
    label: "Servicios extras",
    roles: [SuperAdmin],
    icon: "clipboard",
  },
];
