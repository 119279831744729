import isNullOrEmptyProperty from './isNullOrEmptyProperty';

 const ResolverGraphqlError = (error) => {
    if (error.networkError) {
      if (isNullOrEmptyProperty(error.networkError) == false) {
        const { InnerException, Message } = error.networkError.result[0];
        if (InnerException || Message) {
          if (InnerException) return InnerException.Message;
          if (Message) return Message;
        }
      }
    }
  
    return error;
  };
  export default ResolverGraphqlError;