import { gql } from "@apollo/client";

export const ZONA_FRAGMENT = gql`
  fragment ZonaFragment on Zona {
    id
    nombre
    activo
    eliminado
    presupuesto
    geocercasTotal
    subcontratosTotal
    empleadosTotal
    listaSupervisores {
      id
      usuario {
        id
        persona {
          id
          nombre
          apellidoPaterno
        }
      }
    }
  }
`;

export const OBTENER_POR_ID_ZONA = gql`
  query ($id: ID!) {
    zonas {
      obtenerPorId(id: $id) {
        ...ZonaFragment
      }
    }
  }
  ${ZONA_FRAGMENT}
`;

export const OBTENER_PAGINADO_ZONAS = gql`
  query ZonasPage($query: QueryInput!, $idUsuario: ID!) {
    zonas {
      obtenerPaginado(query: $query, idUsuario: $idUsuario) {
        totalItems
        items {
          ...ZonaFragment
          presupuestoConsumido
        }
      }
    }
  }
  ${ZONA_FRAGMENT}
`;

export const OBTENER_LISTA_ZONAS = gql`
  query (
    $idUsuario: ID!
    $filter: String
    $activoZona: Boolean
    $activoGeocerca: Boolean
  ) {
    zonas {
      obtenerTodosCalendario(
        idUsuario: $idUsuario
        filter: $filter
        activoZona: $activoZona
        activoGeocerca: $activoGeocerca
      ) {
        id
        nombre
        listaGeocercas {
          id
          nombre
          direccion
          mapa
          empleadosIds
        }
      }
    }
  }
`;

export const OBTENER_ZONAS_GEOCERCAS = gql`
  query (
    $idUsuario: ID!
    $filter: String
    $activoZona: Boolean
    $activoGeocerca: Boolean
    $nombrezona: String
  ) {
    zonas {
      obtenerZonasGeocercas(
        idUsuario: $idUsuario
        filter: $filter
        activoZona: $activoZona
        activoGeocerca: $activoGeocerca
        nombrezona: $nombrezona
      ) {
        id
        nombre
        listaGeocercas {
          id
          nombre
          direccion
          empleadosIds
          mapa
        }
      }
    }
  }
`;

export const OBTENER_ZONAS_NOMBRE = gql`
  query ($idUsuario: ID!) {
    zonas {
      obtenerZonasNombres(idUsuario: $idUsuario) {
        id
        nombre
      }
    }
  }
`;

export const OBTENER_TODOS = gql`
  query {
    zonas {
      obtenerTodos {
        id
        nombre
      }
    }
  }
`;
