const hour_minute = (dateTime, isMid = true) => {
  const t = new Date(dateTime);

  const hr = ("0" + t.getHours()).slice(-2);
  const min = ("0" + t.getMinutes()).slice(-2);
  const mid = hr < 12 ? "a.m." : "p.m.";
  if (isMid) return hr + ":" + min + " " + mid;
  else return hr + ":" + min;
};

export default hour_minute;
