import React from "react";
import create from "zustand";
import CryptoJS from "crypto-js";
import { persist } from "zustand/middleware";
import { PlataformaEnum, SuscripcionEstatus } from "./utils/config";

const setValue = (set, item, value) => set({ [item]: value });

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), "1Nt3Mpu55");
};

const decryptData = (data) => {
  var bytes = CryptoJS.AES.decrypt(data, "1Nt3Mpu55");
  var text = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(text);
};

const useStoreGeneral = create(
  persist(
    (set, get) => ({
      // Global States
      suscripcionEstatus: SuscripcionEstatus.SIN_SUSCRIPCION,
      plataforma: "",

      // Reportes
      checkGafeteEmpleado: false,
      checkGafeteAdministrador: false,
      checkGafeteResidente: false,
      checkGafeteSupervisor: false,
      checkGafeteSubcontratoEmpresa: false,
      checkGafeteSubcontratoIndividual: false,
      checkDetalleGeocercas: false,
      checkPresupuesto: false,
      checkCalculoPrenomina: false,

      // Funcionalidad
      checkCalculoNomina: false,
      checkReconocimientoFacial: false,
      checkEvaluarEmpleados: false,
      dias: 0,
      checkDiasIlimitados: false,
      checkHistorialInactivo: false,
      isPlanGratis: false,
      // Bloquea el acceso a algunas rutas en caso de exceder la cantidad de registros
      // Esto en caso que cambie a un plan menor
      limiteSuperado: false,

      // Variables
      pagoConfirmado: false,
      cambioPlan: false,

      // Set Values
      setStoreGeneral: (data) => {
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            setValue(set, key, data[key]);
          }
        }
      },

      setPagoConfirmado: (data) => setValue(set, "pagoConfirmado", data),
      setCambioPlan: (data) => setValue(set, "cambioPlan", data),
      setSocketActivo: (data) => setValue(set, "socketActivo", data),
      setPlataforma: (data) =>
        setValue(set, "plataforma", PlataformaEnum[data]),
      setSuscripcionEstatus: (data) =>
        setValue(set, "suscripcionEstatus", SuscripcionEstatus[data]),
    }),
    {
      name: "_intempuss",
      serialize: (data) => encryptData(data),
      deserialize: (data) => decryptData(data),
      getStorage: () => localStorage,
    }
  )
);

export default useStoreGeneral;
