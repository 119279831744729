export const PlataformaEnum = {
  GENERAL: "GENERAL",
  ADMINISTRACION: "ADMINISTRACION",
};

export const SuscripcionEstatus = {
  ACTIVO: "ACTIVO",
  PENDIENTE: "PENDIENTE",
  FINALIZADA: "FINALIZADA",
  CANCELADA: "CANCELADA",
  SIN_SUSCRIPCION: "SIN_SUSCRIPCION",
};
