import { gql } from "@apollo/client";

export const ACTUALIZAR_LIMITES_PLAN = gql`
  query ($usuarioId: ID!) {
    suscripcion {
      actualizarLimitesPlan(usuarioId: $usuarioId)
    }
  }
`;

export const OBTENER_INFORMACION_CAMBIO_PLAN = gql`
  query ($usuarioId: ID!, $planNuevoId: ID!) {
    suscripcion {
      obtenerInformacionCambioPlan(
        usuarioId: $usuarioId
        planNuevoId: $planNuevoId
      )
    }
  }
`;

export const OBTENER_DETALLES_PLAN_ACTIVO = gql`
  query ($usuarioId: ID!) {
    suscripcion {
      obtenerDetallesPlanActivo(usuarioId: $usuarioId) {
        planId
        opcionSeleccionada
        especificarOtro
        estatusOrdenConekta
        numEmpleados
        numEmpleadosRegistrados
        numAdministradores
        numAdministradoresRegistrados
        numResidentes
        numResidentesRegistrados
        numSupervisores
        numSupervisoresRegistrados
        numSubcontratosIndividual
        numSubcontratosIndividualRegistrados
        numSubcontratosEmpresa
        numSubcontratosEmpresaRegistrados
        numEmpresas
        numEmpresasRegistradas
        numGeocercas
        numGeocercasRegistrados
        numZonas
        numZonasRegistrados
        checkEmpleadosIlimitados
        checkResidentesIlimitaddos
        checkAdministradoresIlimitados
        checkSupervisoresIlimitados
        checkSubcontratosIndividualIlimitados
        checkSubcontratosEmpresaIlimitados
        checkEmpresasIlimitados
        checkGeocercasIlimitados
        checkZonasIlimitados
        checkCodigoQR
        checkReconocimientoFacial
        checkCalculoPrenomina
        checkCalculoNomina
        checkEvaluarEmpleados
        suscripcionId
        suscripcionFechaFinFacturacion
        suscripcionFechaInicioFacturacion
        suscripcionPago
        suscripcionEstatus
        saldoPendiente
        isCambioPlanMenor
        nombrePlanCambio
        planNombre
        planNumero
        intervaloSuscripcion
        metodoPago
        idPlanPayPal
        isProrroga
        asesorPersonalizado
        pendienteCancelacion
        isPlanGratis
      }
    }
  }
`;

export const CAMBIAR_PLAN_ACTUAL_PAYPAL = gql`
  query ($usuarioId: ID!, $planNuevoId: ID!) {
    suscripcion {
      cambiarPlanActualPayPal(usuarioId: $usuarioId, planNuevoId: $planNuevoId)
    }
  }
`;

export const OBTENER_NOMBRES_PLANES_COMPRADOS = gql`
  query ($usuarioId: ID!) {
    suscripcion {
      obtenerNombresPlanesComprados(usuarioId: $usuarioId)
    }
  }
`;

export const OBTENER_HISTORIAL_COMPRAS = gql`
  query (
    $usuarioId: ID!
    $pagina: Int
    $planFiltrado: ID
    $estatusFiltrado: String
  ) {
    suscripcion {
      obtenerHistorialCompras(
        usuarioId: $usuarioId
        pagina: $pagina
        planFiltrado: $planFiltrado
        estatusFiltrado: $estatusFiltrado
      ) {
        items {
          id
          referencia
          fechaCompra
          fechaExpiracionSuscripcion
          metodoPago
          isUltimaCompra
          nombrePlan
          costoPlan
          tieneDescuento
          cantidadDescuento
          tieneServicioExtra
          costoServicioExtra
          tieneDiasProporcionales
          costoDiasProporcionales
          tieneAdeudo
          cantidadAdeudo
          estatus
          total
        }
        totalItems
      }
    }
  }
`;

export const OBTENER_SALDO_PENDIENTE = gql`
  query ($usuarioId: ID!) {
    suscripcion {
      obtenerSaldoPendientePorIdUsuario(usuarioId: $usuarioId)
    }
  }
`;
