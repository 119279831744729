const porcentaje = (value) => {
    let format = value || 0;
     format = format.toFixed(2);
     if(format % 1){
        return format + "%";
     }
     return parseInt(format) + "%";
  };
  
  export default porcentaje;
  