import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import logo from './pageloader4.gif'

const CustomLoader = () => {
  return (
    <Dimmer active={true} inverted style={{ position: 'fixed' }}>
      <img src={logo} alt='loading...' />
    </Dimmer>
  );
};

export default CustomLoader;
