const estatus_check = (status) => {
  switch (status) {
    case "JORNADA_COMPLETA":
      return "A TIEMPO";
    case "JORNADA_INCOMPLETA":
      return "RETARDO";
    case "FALTA":
      return "FALTA";
    case "PERMISO":
      return "PERMISO";
    case "JUSTIFICACION_FALTA":
      return "JUSTIFICACIÓN FALTA";
    case "DESCANSO":
      return "DESCANSO";
    default:
      return "";
  }
};
export default estatus_check;
