import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import CustomLoader from "./components/common/CustomLoader";
import LayoutBase from "./components/Layout";
import { ZonasProvider } from "./Context/ZonasContext";
import history from "./utils/history";
import { usuarioLogueado } from "./utils/loggedInfo";
import { PlataformaEnum, SuscripcionEstatus } from "./zustand/utils/config";
import useStoreGeneral from "./zustand/useStoreGeneral";
import config from "./config";

const AccountRouter = lazy(() => import("./components/Account"));
const InicioRouter = lazy(() => import("./components/Inicio"));
const CatalogosRouter = lazy(() => import("./components/Catalogos"));
const NominaRouter = lazy(() => import("./components/Nomina"));
const PerfilRouter = lazy(() => import("./components/Perfil"));
const ProcesoCompraRouter = lazy(() => import("./components/ProcesoCompra"));

const listofPages = [
  "/registro",
  "/login",
  "/solicitar-contrasena",
  "/recuperar-contrasena",
  "/verificar-correo",
  "/membresias",
];

const Routes = ({ location }) => {
  const { suscripcionEstatus, plataforma } = useStoreGeneral();

  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      <Suspense fallback={<CustomLoader />}>
        <Switch location={location}>
          <Route path="/membresias" component={ProcesoCompraRouter} />
          {!usuarioLogueado() ? (
            <Route path="/" component={AccountRouter} />
          ) : (
            <Redirect from="**" to="/inicio" />
          )}
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <>
        {usuarioLogueado() ? (
          <>
            {plataforma === PlataformaEnum.GENERAL ? (
              <>
                {suscripcionEstatus === SuscripcionEstatus.ACTIVO ? (
                  <ZonasProvider>
                    <LayoutBase>
                      <Suspense fallback={<CustomLoader />}>
                        <Switch location={location}>
                          <Route
                            exact
                            path="/inicio"
                            component={InicioRouter}
                          />
                          <Route
                            path="/catalogos"
                            component={CatalogosRouter}
                          />
                          <Route path="/nomina" component={NominaRouter} />
                          <Route
                            exact
                            path="/perfil"
                            component={PerfilRouter}
                          />
                          <Redirect from="**" to="/inicio" />
                        </Switch>
                      </Suspense>
                    </LayoutBase>
                  </ZonasProvider>
                ) : (
                  history.push("/membresias")
                )}
              </>
            ) : plataforma === PlataformaEnum.ADMINISTRACION ? (
              // Layout de Administración
              <ZonasProvider>
                <LayoutBase>
                  <Suspense fallback={<CustomLoader />}>
                    <Switch location={location}>
                      <Route exact path="/inicio" component={InicioRouter} />
                      <Route path="/catalogos" component={CatalogosRouter} />
                      <Route exact path="/perfil" component={PerfilRouter} />
                      <Redirect from="**" to="/inicio" />
                    </Switch>
                  </Suspense>
                </LayoutBase>
              </ZonasProvider>
            ) : (
              history.push("/membresias")
            )}
          </>
        ) : (
          // history.push("/registro")
          history.push("/login")
        )}
      </>
    );
  }
};

export default withRouter(Routes);
