const money = (text, showDefault = true) => {
  if (!text && showDefault) {
    return "$0.00";
  }

  if (!text && !showDefault) return text;
  const parts =
    text.constructor === String
      ? text.includes("$")
        ? text.replace("$", "").split(".")
        : text.constructor === String
        ? text.toString().split(".")
        : text.toFixed(4).split(".")
      : text.toString().split(".");
  const val = `${
    parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "")
  }`;
  return "$" + val;
};

export default money;
