export const usuarioLogueado = () => {
  const z = localStorage.getItem('usuarioLogueado');
  return JSON.parse(z);
};

export const setLoguedInfo = (data) => {
  localStorage.setItem('INTEMPUSS_AUTH_JWT_ID', data.idToken);
  if (!data.foto) data.foto = '../../../img/userDefault.svg';
  localStorage.setItem('usuarioLogueado', JSON.stringify(data));
};
