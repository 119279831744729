const tipo_check = (status) => {
  switch (status) {
    case 'CHECK_IN':
      return 'CHECK IN';
    case 'CHECK_IN_COMIDA':
      return 'CHECK IN COMIDA';
    case 'CHECK_OUT_COMIDA':
      return 'CHECK OUT COMIDA';
    case 'CHECK_OUT':
      return 'CHECK OUT';
    case 'CHECK_IN_PERMISO':
      return 'CHECK IN PERMISO';
    case 'CHECK_OUT_PERMISO':
      return 'CHECK OUT PERMISO';
    case 'CHECK_IN_HORA_EXTRA':
      return 'CHECK IN HORA EXTRA';
    case 'CHECK_OUT_HORA_EXTRA':
      return 'CHECK OUT HORA EXTRA';
    case 'REGISTRO_INTERNO':
      return 'REGISTRO INTERNO';
    case 'TERMINO_POR_HOY':
      return 'TERMINO POR HOY';
    default:
      return '';
  }
};
export default tipo_check;
