const ddmmyyyy = (date) => {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(date);
    d.setDate(d.getDate());
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  };
  
  export default ddmmyyyy;
  