/* eslint-disable no-undef */
const googleMapsAPIKey = 'AIzaSyAWIrsiH8dtcdToKYtgsTNpi_dUFHgh2u8';

const configGoogleMaps = {
  apiKey: googleMapsAPIKey,
  url: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${googleMapsAPIKey}&libraries=places`,
};

const configConekta = {
  /// Api Key Intempuss PRUEBAS IXS **********
  //apiKey: 'key_GQ8TdyYzgPh9jxWRRqWC3kw',

  //// ************************************************
  /// Api Key Cuenta Intempuss - Pruebas
  // apiKey: "key_HZRNaxnVHT6kuWxrYqnmFNw",

  /// Api Key Cuenta Intempuss - Produccion
  apiKey: "key_ZmUqy7nr4PC7PC84P61eFxA",
};

const configPayPal = {
  // Cliente Id Sandbox
  clientId:
    'AZGVbVnJPa6EqoGhDGTdK1phpS288nOrt1yx-JAgl7i1DFmXWSESrLKl9_nQbvNi6NQeVE6rmz6OQIt2',
};

const webSocket = {
  ///// Desarrollo
  wssUrl: 'wss://ibw531pda4.execute-api.us-west-2.amazonaws.com/desarrollo',
  ///// Produccion
  // wssUrl: "wss://ibw531pda4.execute-api.us-west-2.amazonaws.com/desarrollo",
};

const dev = {
  apiUrl: 'https://localhost:44320/',
  // apiUrl: "https://ap4gv0zoy1.execute-api.us-west-2.amazonaws.com/Prod/", //// ... ADMIN  ....
};

const test = {
  apiUrl: 'https://isz9vj0fu3.execute-api.us-west-2.amazonaws.com/Prod/',
  // apiUrl: "https://ap4gv0zoy1.execute-api.us-west-2.amazonaws.com/Prod/", //// ... ADMIN  ....
};

const adminTest = {
  apiUrl: 'https://oae62w455i.execute-api.us-west-2.amazonaws.com/Prod/',
};

const admin = {
  apiUrl: 'https://ap4gv0zoy1.execute-api.us-west-2.amazonaws.com/Prod/',
};

const production = {
  apiUrl: 'https://yynkkm3hvg.execute-api.us-west-2.amazonaws.com/Prod/', ///// .... CLIENTES ....
  // apiUrl: "https://ap4gv0zoy1.execute-api.us-west-2.amazonaws.com/Prod/", //// ... ADMIN  ....
};

const config =
  process.env.REACT_APP_STAGE === 'test'
    ? test
    : process.env.REACT_APP_STAGE === 'adminTest'
    ? adminTest
    : process.env.REACT_APP_STAGE === 'production'
    ? production
    : process.env.REACT_APP_STAGE === 'admin'
    ? admin 
    : dev;

const isAdministracion =
  process.env.REACT_APP_STAGE === 'admin' ||
  process.env.REACT_APP_STAGE === 'adminTest'
    ? true
    : false;

export default {
  ...config,
  wssUrl: webSocket.wssUrl,
  validFileTypes: ['jpg', 'png', 'gif', 'jpeg', 'bmp', 'svg'],
  AddressMicroService:
    'https://s1iv45rryi.execute-api.us-west-2.amazonaws.com/Prod/api/zip/BuscarCP?codigo=',
  googleMaps: configGoogleMaps,
  reportingService: 'https://intempuss-reportes.jsreportonline.net/',
  conekta: configConekta,
  paypal: configPayPal,
  isAdministracion: isAdministracion,
};

export const reportCredentials =
  'aW50ZW1wdXNzLmludGVyYXhpc0BnbWFpbC5jb206MW5UZVdwVTVALypvIzIzXys=';
