import axios from 'axios';
import config from '../config';

const Axios = axios.create({
  baseURL: config.apiUrl,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('INTEMPUSS_AUTH_JWT_ID')}`,
  },
});

export default Axios;
