import React, { useEffect, createContext, useReducer } from "react";
import { zonasReducer } from "./zonasReducer";
import { usuarioLogueado } from "../utils/loggedInfo";

// GraphQL
import { useApolloClient } from "@apollo/react-hooks";
import { OBTENER_ZONAS_NOMBRE } from "../graphql/queries/zonas";
import { isNullOrEmptyProperty } from "../pipes";

const initialState = {
  zonas: [],
};

export const ZonasContext = createContext();

export const ZonasProvider = ({ children }) => {
  const [zonasState, dispatch] = useReducer(zonasReducer, initialState);

  const { idUsuario, rol } = usuarioLogueado();
  const apollo = useApolloClient();

  useEffect(() => {
    if (
      rol !== "Super Administrador" &&
      rol !== "Ventas" &&
      rol !== "Socios" &&
      rol !== "Marketing"
    ) {
      (async () => await actualizarZonas())();
    }
  }, []);

  const actualizarZonas = async () => {
    const {
      data: {
        zonas: { obtenerZonasNombres },
      },
    } = await apollo.query({
      query: OBTENER_ZONAS_NOMBRE,
      variables: {
        idUsuario,
      },
    });

    dispatch({
      type: "actualizarListaZonas",
      payload: obtenerZonasNombres,
    });
  };

  return (
    <ZonasContext.Provider
      value={{
        zonasState,
        actualizarZonas,
      }}
    >
      {children}
    </ZonasContext.Provider>
  );
};
