import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "@fortawesome/fontawesome-free";
import "semantic-ui-css/semantic.min.css";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import config from "./config";
import CustomLoader from "./components/common/CustomLoader";
import { usuarioLogueado } from "./utils/loggedInfo";
import "conekta-card-tokenizer";
import { parseJwt } from "./utils/parseJwt";
import history from "./utils/history";
import Swal from "sweetalert2";
// import { ZonasProvider } from "./Context/ZonasContext";

const logout = () => {
  localStorage.removeItem("usuarioLogueado");
  localStorage.removeItem("INTEMPUSS_AUTH_JWT_ID");
  localStorage.removeItem("_intempuss");;
};

const httpLink = new HttpLink({ uri: config.apiUrl.concat("graphql") });

// Set Configuration Conekta
window.Conekta.setPublicKey(config.conekta.apiKey);
window.Conekta.setLanguage("es");

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { idToken } = usuarioLogueado();

  // check if your session has already expired
  const { exp } = parseJwt(idToken);
  const timeNow = Math.round(new Date().getTime() / 1000);
  if (timeNow >= exp) {
    return Swal.fire({
      title: "Advertencia",
      text: "Su sesión a expirado, por su seguridad lo enviaremos a iniciar sesion nuevamente",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Iniciar sesión",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        history.push("/login");
        window.location.reload();
      }
    });
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: idToken ? `Bearer ${idToken}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError.statusCode === 401) {
    console.log(graphQLErrors);
    logout();
  }
});

const link = ApolloLink.from([errorLink, httpLink]);

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions: { watchQuery: { fetchPolicy: "cache-and-network" } },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Suspense fallback={<CustomLoader />}>
      {/* <ZonasProvider> */}
      <App />
      {/* </ZonasProvider> */}
    </Suspense>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
