import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Dropdown,
  Image,
  Menu,
  Icon,
  List,
  Popup,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import history from "../../utils/history";
import { usuarioLogueado } from "../../utils/loggedInfo";
import {
  menuOpciones1,
  menuOpciones2,
  menuOpcionesAdmin,
  ConsultarZonas,
  menuOpcionesAdministradorGeocerca,
} from "./menuOpciones";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { OBTENER_ZONAS_NOMBRE } from "../../graphql/queries/zonas";
import { Administrador } from "../../utils/rolConstants";
import { ZonasContext } from "../../Context/ZonasContext";
import apiRequest from "../../api/apiRequest";
import config from "../../config";
import CustomLoader from "../common/CustomLoader";
import useStoreGeneral from "../../zustand/useStoreGeneral";

const LayoutBase = ({ children }, refetch) => {
  const currentPage = children.props.children.props.location.pathname;
  const [opciones, setOpciones] = useState([]);
  const { giroEmpresa, rol, isCliente, userPermissions } = usuarioLogueado();
  const { zonasState } = useContext(ZonasContext);
  const [loading, setLoading] = useState(false);
  const {
    checkCalculoNomina,
    checkReconocimientoFacial,
    checkEvaluarEmpleados,
    checkPresupuesto,
  } = useStoreGeneral();

  useEffect(() => {
    if (
      rol === "Super Administrador" ||
      rol === "Ventas" ||
      rol === "Socios" ||
      rol === "Marketing"
    )
      setOpciones(menuOpcionesAdmin);
    else if(rol === "Administrador" && !isCliente && !userPermissions?.isAdminGeneral){
      setOpciones([...menuOpciones1, ...menuOpcionesAdministradorGeocerca]);
      if (zonasState.zonas.length > 0) {
        setZonasHeader();
      }
    } 
    else {
      setOpciones([...menuOpciones1, ...menuOpciones2]);
      if (zonasState.zonas.length > 0) {
        setZonasHeader();
      }
    }
  }, [zonasState]);

  const setZonasHeader = () => {
    const zonasHeader = {
      icon: "map",
      label: "Geocercas",
      roles: [Administrador],
      subMenus: zonasState.zonas.map((x) => ({
        route: "/catalogos/zonas/geocercas",
        params: {
          zonaId: x.id,
          nombreZona: x.nombre,
          ruta: "/catalogos/zonas",
        },
        label: x.nombre,
        roles: [Administrador],
      })),
    };
    if (menuOpciones1.length > 2) {
      menuOpciones1.pop();
    }
    menuOpciones1.push(zonasHeader);
    const opciones = userPermissions?.isAdminGeneral ? menuOpciones2 : menuOpcionesAdministradorGeocerca;
    setOpciones([...menuOpciones1, ...opciones]);
  };

  const styleContainer = {
    marginTop: "2.5vw",
  };

  const contentPopupByItem = (item) => {
    switch (item) {
      case "Justificar Faltas":
        return "Sólo se puede justificar falta dentro de los tres primeros días posteriores a ella.";
      case "Permisos":
        return "Sólo se permite asignar permisos dos días después de la fecha actual.";
    }
  };

  return (
    <div>
      {loading && <CustomLoader />}
      <Menu fixed="top" inverted>
        <Container className="logoContainer">
          <Image
            src={
              rol === "Super Administrador"
                ? "../../../img/LogoAdministración.svg"
                : "../../../img/Logo.svg"
            }
            style={{
              marginLeft: "2%",
              marginTop: "1%",
              marginBottom: "1%",
              cursor: "pointer",
              width: rol === "Super Administrador" ? "25vw" : "15vw",
            }}
            onClick={() => {
              history.push("/inicio");
            }}
          />
        </Container>

        <Menu.Menu position="right">
          <Dropdown
            refetch={refetch}
            text={localStorage.getItem("nombre")}
            icon={
              <>
                <div
                  style={{
                    backgroundImage:
                      "url(" + localStorage.getItem("urlImagen") + ")",
                  }}
                  className="img_perfil_header"
                />
                <div
                  style={{
                    backgroundColor: "#55FADE",
                    borderRadius: "1vw",
                    padding: "0.3vw",
                    width: "1.5vw",
                    height: "1.5vw",
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    right: "1vw",
                    top: "3.3vw",
                  }}
                >
                  <Icon name="cog" color="black" style={{ margin: 0 }} />
                </div>
              </>
            }
            pointing
            className="link item"
          >
            <Dropdown.Menu>
              {isCliente && (
                <Dropdown.Item
                  icon="user"
                  text="Perfil"
                  onClick={() => {
                    history.push("/perfil");
                  }}
                />
              )}
              {rol !== "Super Administrador" && isCliente && (
                <>
                  <Dropdown.Item
                    onClick={() => history.push("/catalogos/planCompras")}
                    icon="payment"
                    text="Pagos y Facturación"
                  />
                  <Dropdown.Item
                    onClick={() => history.push("/catalogos/historialCompras")}
                    icon="history"
                    text="Historial de compras"
                  />
                  <Dropdown.Divider />
                </>
              )}
              <Dropdown.Item
                icon={"sign-out"}
                text="Cerrar Sesión"
                onClick={() => {
                  if (menuOpciones1.length > 2) {
                    menuOpciones1.pop();
                  }

                  localStorage.clear();
                  history.push("/login");
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
      <Container
        id="iframeid"
        className="opcionesMenu"
        style={{ marginTop: "2vw" }}
      >
        {opciones.map(({ icon, label, route, subMenus, roles }, k) => {
          const isOptionSelected =
            subMenus && subMenus.some((z) => z.route === currentPage);
          if (subMenus && roles.find((x) => x === rol)) {
            return (
              <div
                className={
                  isOptionSelected ? "contenedorMenuu aqui" : "contenedorMenuu"
                }
              >
                {/* <div className={'contenedorMenuu'}> */}
                <Icon name={icon} />
                <Dropdown key={k} text={label} simple item>
                  <Dropdown.Menu>
                    {subMenus.map((sub, jey) => {
                      if (
                        sub.label === "Cálculo Prenómina" &&
                        !checkCalculoNomina
                      ) {
                        return;
                      }

                      if (sub.label === "Swipe" && !checkReconocimientoFacial) {
                        return;
                      }

                      if (
                        sub.label === "Evaluación de Empleado" &&
                        !checkEvaluarEmpleados
                      ) {
                        return;
                      }

                      if (sub.label === "Presupuesto") {
                        if (!checkPresupuesto) {
                          return;
                        } else {
                          if (
                            giroEmpresa !== "Construcción" &&
                            giroEmpresa !== "Seguridad"
                          ) {
                            return;
                          }
                        }
                      }

                      if (sub.roles && sub.roles.find((x) => x === rol)) {
                        return (
                          <>
                            {sub.label === "Justificar Faltas" ||
                            sub.label === "Permisos" ? (
                              <Popup
                                position={"right center"}
                                trigger={
                                  <Dropdown.Item
                                    className="menuItem"
                                    icon={sub.icon}
                                    text={sub.label}
                                    key={jey}
                                    onClick={() => {
                                      history.push(
                                        sub.route,
                                        sub.params ? { ...sub.params } : {}
                                      );
                                    }}
                                  ></Dropdown.Item>
                                }
                                content={contentPopupByItem(sub.label)}
                                basic
                              />
                            ) : (
                              <Dropdown.Item
                                className="menuItem"
                                icon={sub.icon}
                                text={sub.label}
                                key={jey}
                                onClick={() => {
                                  history.push(
                                    sub.route,
                                    sub.params ? { ...sub.params } : {}
                                  );
                                }}
                              ></Dropdown.Item>
                            )}
                          </>
                        );
                      }
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          } else {
            if (roles && roles.find((x) => x === rol)) {
              return (
                <>
                  <List
                    style={{ marginTop: 70, cursor: "pointer", marginLeft: 20 }}
                    horizontal
                  >
                    <List.Item onClick={() => history.push(route)}>
                      <Icon name={icon} />
                      {label}
                    </List.Item>
                  </List>
                </>
              );
            }
          }
        })}
      </Container>
      <Container className="todoContenido" style={styleContainer}>
        {children}
      </Container>
    </div>
  );
};

LayoutBase.propTypes = {
  children: PropTypes.element,
  location: PropTypes.any,
};

export default LayoutBase;
