import React, { useEffect, useLayoutEffect, useState } from "react";
import "./styles/estilos_generales.css";
import { Router } from "react-router-dom";
// App Routes
import Routes from "./Routes";
import history from "./utils/history";
import { useLazyQuery } from "@apollo/client";
import { ACTUALIZAR_LIMITES_PLAN } from "./graphql/queries/suscripciones";
import { isNullOrEmptyProperty } from "./pipes";
import { usuarioLogueado } from "./utils/loggedInfo";
import useStoreGeneral from "./zustand/useStoreGeneral";
import CustomLoader from "./components/common/CustomLoader";

function App() {
  const usuario = usuarioLogueado();
  const [loading, setLoading] = useState(false);
  const { setStoreGeneral, plataforma } = useStoreGeneral();

  const [updateGlobalState] = useLazyQuery(ACTUALIZAR_LIMITES_PLAN, {
    variables: {
      usuarioId: usuario?.idUsuario,
    },
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      setLoading(false);
      if (res.suscripcion.actualizarLimitesPlan.data !== null) {
        setStoreGeneral(res.suscripcion.actualizarLimitesPlan.data);
      }
    },
    onError: (err) => {
      setLoading(false);
      console.log(err);
    },
  });

  useLayoutEffect(() => {
    if (!isNullOrEmptyProperty(usuario)) {
      if (plataforma === "GENERAL") {
        setLoading(true);
        updateGlobalState();
      }
    } else {
      localStorage.clear();
    }
  }, []);

  return (
    <>
      {loading && <CustomLoader />}
      <Router history={history}>
        <Routes />
      </Router>
    </>
  );
}

export default App;
